module.exports.FactArray = [
  "Indonesia consists only of islands - 13,667 total",
  "During World War II, the very first bomb dropped on Berlin by the Allies killed the only elephant in the Berlin Zoo",
  "People who ride on roller coasters have a higher chance of having a blood clot in the brain",
  "The tallest freestanding sculpture in the world is Chief Crazy Horse in South Dakota, USA",
  "Marie Curie, the Nobel prize winning scientist who discovered radium, died of radiation poisoning",
  "898 tornadoes were recorded to have occurred in the United States in the year 2000.",
  'The word Popcorn is derived from the middle English word "poppe," which means "explosive sound"',
  'The food that is digested in your stomach is called "chyme."',
  "Alcohol beverages have all 13 minerals necessary for human life",
  'The sentence "The quick brown fox jumps over the lazy dog." uses everyletter in the alphabet. (Developed by Western Union to Test telex/twxcommunications)',
  "The word housekeeping was invented by Shakespeare",
  "The only two days of the year in which there are no professional sportsgames (MLB, NBA, NHL, or NFL) are the day before and the day after theMajorLeague All-Star Game.",
  "In the great fire of London in 1666 half of London was burnt down but only 6 people were injured",
  "Lack of sleep can affect your immune system and reduce your ability to fight infections",
  "All dogs are the descendant of the wolf. These wolves lived in eastern Asia about 15,000 years ago",
  "It is not possible to tickle yourself. The cerebellum, a part of the brain, warns the rest of the brain that you are about to tickle yourself. Since your brain knows this, it ignores the resulting sensation",
  'Parma ham is only Parma ham if it is made in the Parma region of Italy. The British chain supermarket Asda, made and packaged its own "Parma ham" and was successfully sued by the real Parma ham people (Parma Ham Trade Association)',
  "With winds of 50 miles per hour, The Statue of Liberty sways three inches and the torch sways five inches",
  "A famous bullfighter, Lagarijo, killed 4,867 bulls in the 19th century.",
  "Police detectives have used snapping turtles to help them locate dead bodies",
  "In most advertisements, including newspapers, the time displayed on a watch is 10:10",
  "The national sport of Japan is sumo wrestling",
  "The early occurrence of a fetus yawning is at eleven weeks after conception",
  "In a month, a fingernail grows an eighth of an inch",
  "Edward VIII did not officially become the King of England as he abdicated the throne to marry an American divorcee",
  'The book "Little Red Riding Hood" was banned in 1990 by two school districts in California. They did this because in the book there was a picture of a basket that had a bottle of wine in it',
  "The reason why golf balls have dimples on them is because it helps in the ball to move a farther distance by reducing drag",
  "Americans consume the most peanut butter in the world",
  "Celtic warriors sometimes fought their battles naked, their bodies dyed blue from head to toe",
  "To make butter more attractive in colour, carrot juice was used by people in the Middle Ages",
  "Early hockey games allowed as many as 30 players a side on the ice",
  "Most fleas do not live past a year old",
  "It takes seven to ten days to make a jelly belly jellybean",
  "Some asteroids have other asteroids orbiting them",
  "Maine is the only state whose name is just one syllable",
  "The male praying mantis cannot copulate while its head is attached to its body. The female initiates sex by ripping the males head off",
  "There is enough concrete in the Hoover Dam to pave a two lane highway from San Francisco to New York",
  "Americans on the average eat 18 acres of pizza every day",
  "Every 238 years, the orbits of Neptune and Pluto change making Neptune at times the farthest planet from the sun",
  "There is a certain species of kangaroo that is only 2.5 centimetres long when it is born",
  "In a lifetime, the average house cat spends approximately 10,950 hours purring",
  'The real name of Toto the dog in "The Wizard Of Oz" was Terry',
  "Stannous fluoride, which is the cavity fighter found in toothpaste is made from recycled tin",
  "It takes 12 honeybees to make one teaspoon of honey",
  "Thomas Watson, who was the chairman of IBM in 1943 predicted that their would probably only be a world market for five computers.",
  "The largest hamburger cooked in the world weighed in at 6,040 pounds",
  "The first lighthouse was in Alexandria in 290 B.C",
  "Heinz first started making ketchup in 1876 and the recipe has remained the same ever since",
  "The largest wedding chapel in Las Vegas is the Viva Las Vegas Chapel, which can seat 100 people",
  "The most popular name for a pet in the United States is Max",
  "Spiral staircases in medieval castles are running clockwise. This is because all knights used to be right-handed. When the intruding army would climb the stairs they would not be able to use their right hand which was holding the sword because of the difficulties of climbing the stairs. Left-handed knights would have had no troubles, except left-handed people could never become knights because it was assumed that they were descendants of the devil",
  "The largest shopping mall in the world is the West Edmonton Mall located in Edmonton, Alberta, Canada",
  "The CN Tower located in Toronto, Ontario Canada took a total construction time of 40 months to complete at an original cost of $63 million",
  "The 20th president of the United States, James Garfield, was able to write Greek with one hand and Latin with the other at the same time",
  "The country of Andorra has a zero percent unemployment rate",
  "In Los Angeles, there are fewer people than there are automobiles",
  "A woman has approximately 4.5 litres of blood in her body, while men have 5.6 litres",
  "In India, pickled ginger, minced mutton and a cottage cheese like substance are popular pizza toppings",
  "Oral-B were the first toothbrushes to go to the moon when they were aboard the Apollo 11 mission",
  "A maple tree is usually tapped when the tree is at least 45 years old and has a diameter of 12 inches",
  "In 1998, a law passed in the U.S. state of Virginia allows drivers to keep their road kill, as long as they report it within 12 hours. updated",
  "A language becomes extinct in this world every two weeks",
  "An acre of trees can remove about 13 tons of dust and gases every year from the surrounding environment",
  "The decomposition point of Olive Oil is 220 degrees Celsius",
  "Ten radishes only contain eight calories",
  "Annually a thousand people are killed by scorpions in Mexico",
  "Every year, 100 million sharks are killed by people",
  "Tug of war was an Olympic event from 1900-1920",
  "Of all the countries, Brazil has the most plant species, with over 56,000",
  "One female mouse can produce up to 100 babies a year",
  "Impotence is grounds for divorce in 26 U.S. states",
  "Women who are romance novel readers are reported to make love 74% more often with their partners than women who do not read romance novels.",
  "The average lifespan of a human taste bud is ten days",
  'The monogram "RR" for Rolls-Royce has never been altered, except for when Sir Henry Royce passed away in 1933. Then it was changed from red to black.',
  "People with darker skin will not wrinkle as fast as people with lighter skin",
  "Fido means faithful in Latin",
  "Pebbles cereal was actually named after the shape of the cereal and not the Pebbles Flintstone character",
  "A group of kangaroos is called a mob",
  "Cat's urine glows under a blacklight.",
  "Every three seconds, a new baby is born",
  "More than 260,000 people have been killed by volcanic activity since 1700 AD.",
  "The only predator that polar bears have are humans",
  "Many insects can carry 50 times their own body weight",
  "The last land battle of the U.S. Civil War was fought in Texas",
  "Annually 7 million tons of textiles and clothing is thrown out. Out of this, only 12% is used again or recycled",
  "A scorpion can have up to 12 eyes",
  "A snake charmer in Bangladesh once found 3,500 poisonous cobras and their eggs hidden underneath the floors of two suburban homes",
  "The IRS employees tax manual has instructions for collecting taxes after a nuclear war",
  "There are approximately fifty Bibles sold each minute across the world",
  "The pectin that is found in apples aids in lowering cholesterol levels",
  "Post-It Notes, which are adhesive notes, were invented while looking for a way to improve the acrylate adhesive found in tapes",
  "Crayola Crayons currently has over 120 different crayon colours",
  "Odontophobia is the fear of teeth",
  "The width of a tornado can range from less than ten yards to more than a mile.",
  "In Johannesburg, the average car will be involved in an accident once every four years.",
  "The youngest actress to be nominated as best actress is Keisha Castle-Hughes who was nominated at just 13 years old",
  "The Taj Mahal was actually built for use as a tomb",
  "According to studies, an average roll of toilet paper lasts about five days in the bathroom",
  "Almonds are members of the peach family",
  "The oldest known disease in the world is leprosy",
  "A fall of 30 feet can be survived my most cats",
  "The largest member of the dolphin family are orcas",
  "In 1477, the first diamond engagement ring was given to Mary of Burgundy by Archduke Maximillian of Austria",
  'The hormone replacement drug "Premarin" is made from the urine of pregnant horses',
  "TWIX Caramel Cookie Bars were first introduced in 1979",
  "Nintendo was first establish in 1889 and they started out making special playing cards",
  "People over the age of fifty will start to lose their dislike for foods that taste bitter",
  "In Kentucky, 50 percent of the people who get married for the first time are teenagers",
  "Elephants have been known to learn up to 60 commands",
  "On average 1,668 gallons of water are used by each person in the United States daily",
  "Copper is the second most used metal in the world.",
  "Milton Bradley originally wanted to name the game Twister, Pretzel; but he could not since the name was copyrighted",
  "According to studies, men prefer to have white bedrooms and women prefer to have blue bedrooms",
  "If someone was to fly once around the surface of the moon, it would be equal to a round trip from New York to London",
  "St. Patrick never really drove out any snakes from Ireland. This story was an analogy of how he drove paganism out of Ireland",
  "Fat is important for the development of children and normal growth",
  "The most common seasonings found in American homes are chili powder, cinnamon, and seasoned salts",
  "People who have eaten beetles say that it tastes like apples",
  'Montreal was named after a local mountain "Mont Royal."',
  "Millie the White House dog earned more than 4 times as much as President Bush in 1991. And, rightfully so",
  "In an average lifetime, a person will spend 4 years travelling in an automobile and six months waiting at a red light.",
  "A small drip from a faucet can waste up to 50 gallons of water daily, which is enough water to run a dishwasher twice on a full cycle",
  "Kotex was first manufactured as bandages, during W.W.I",
  "The longest Monopoly game ever played was 1,680 hours long, which is seventy straight days",
  "The first known contraceptive was crocodile dung, used by Egyptians in 2000 B.C",
  "Over 1,600 people in North America have been victims of trunk entrapment (being locked inside of a car trunk)",
  "A rhinoceros horn is made of compacted hair",
  "In 1992, when EuroDisney first opened in France, the public beat some of the park characters because at the time most people had been against the park being built",
  "A jiffy is an actual unit of time for 1/100th of a second. Thus the saying, I will be there in a jiffy.",
  'There is a muppet named Kami that appears on the South African version of the T.V. show "Sesame Street" that is HIV-positive',
  "There are approximately one hundred million people in the United States that have a chronic illness",
  "The oldest working Post Office in the world is located in the village of Sanquer, located in the Scottish Lowlands. It has been operating since 1712",
  "Columbia University is the second largest landowner in New York City, after the Catholic Church",
  "Approximately three jars of peanut butter are sold every second",
  "In Australia, the average person uses 876 gallons of water daily. In Switzerland they use only 77 gallons of water per person daily",
  "Every person has a unique tongue print",
  "Hair will fall out faster on a person that is on a crash diet",
  "In 1890, there was no sunshine for the whole month of December in Westminster in London.",
  "Charles Darwin spent 39 years studying earthworms",
  "The Boeing 737 is nicknamed the Fat Albert",
  "Florida has twice as many lightning injuries and deaths than any other state",
  "Chocolate can be fatal to dogs. Chocolate contains a chemical theobromine, which is poisonous to dogs",
  "In China, there is a species of yam that is used to make a dye",
  "Annually, approximately 46 millions Cokes, five million pounds of french fries, and seven million hamburgers are consumed at Walt Disney World Resort",
  "The Chihuahua Desert is the largest desert in North America, and is over 200,000 square miles",
  "Every continent begins and ends in the same letter. eg AfricA, EuropE",
  "Baseball games between college teams have been played since the Civil War",
  "The real name of actress Whoopi Goldberg is Caryn Elaine Johnson",
  "Researches have discovered that eating five or more apples a week is linked to better functioning of the lungs",
  "Boeing completed more than 15,000 hours of wind-tunnel testing on the first 747",
  "The most popular ethnic food in the United States is Italian food",
  "Parts of the Dead Sea Scrolls appeared for sale in the June 1, 1954 issue of the Wall Street Journal",
  "If the population of China walked past you in single file, the line would never end because of the rate of reproduction",
  "The YKK that you see on zippers stands for Yoshida Kogyo Kabushiki Kaisha which is the name of the founder of the zipper manufacturing company in Japan",
  'The theme song of the Harlem Globetrotters is "Sweet Georgia Brown."',
  "27% of female lottery winners hid their winning ticket in their bras",
  "To lose one pound of fat, a person has to burn approximately 3,500 calories",
  "In 1969, the American side of Niagara Falls was stopped completely for several months",
  "The name for insect poop is frass",
  "A can of Pepsi has 41 grams of sugar. This amount to about seven teaspoons of sugar",
  "Montreal is actually located on an island",
  "There are over 2,000 species of butterflies in the rainforests of South America",
  "The world record for the number of body piercings on one individual is 702, which is held by Canadian Brent Moffat",
  "Before toilet paper was invented, French royalty wiped their bottoms with fine linen",
  "The earliest known example of an organized market for equities dates from Rome, second century B.C",
  "There are over 2,000 different species of cactuses",
  "Each day 400 gallons of recycled blood are pumped through the kidneys",
  "Ten percent of the Russian government's income comes from the sale ofvodka.",
  "Apples, not caffeine, are more efficient at waking you up in the morning",
  "Bananas were discovered by Alexander the Great in 327 B.C. when he conquered India",
  'Levan, which is located in Utah, got its name from "navel" which is levan spelt backwards. It was named this because it is in the center of Utah',
  "Approximately one out of four injuries by athletes involve the wrist and hand",
  "Former U.S. President Abraham Lincoln suffered a nervous breakdown in 1836",
  "Musk is extracted from the bottom of a civet, and is used as an ingredient to make perfumes.",
  "The first human heart transplant happened on December 3, 1967. Unfortunately the patient only lived for eighteen days, succumbing in the end to pneumonia",
  "In New York City there are 6,374.6 miles of streets",
  "The sound made by the Victoria Falls in Zimbabwe is so loud that it can be heard 40 miles away",
  "Ancient Egyptians used to think having facial hair was an indication of personal neglect",
  "In Czechhoslovakia, there is a church that has a chandelier made of human bones",
  "The largest hotel in the world is the MGM Grand, which has 5,034 rooms and is located in Las Vegas, Nevada",
  "The plastic things on the end of shoelaces are called aglets",
  "The fleshy bulbs on each side of your nose are called the Alea (AY-lee) singular Ala (AY-luh)",
  "Male koalas mark their territory by rubbing their chests on a tree. Male koalas have a dark scent gland in the middle of their chest",
  "An octopus has three hearts",
  "Roses generally need around 6 hours of sunlight to grow properly.",
  "Buttermilk does not contain any butter, but is a cultured milk product which is usually made from fat free milk",
  'Pineapples were first called "anana", which is Caribbean for "excellent fruit."',
  "Human birth control pills work on gorillas",
  "The tallest woman that ever lived was Zeng Jinlian who was 8 feet 2 inches tall of China. Shed died at the age of 17",
  'An adult "Gold Frog" measures to be 9.8 millimeters in body length',
  "Each day, anywhere from 35-150 species of life go extinct",
  "Alexander Graham Bell, the inventor of the telephone, never telephoned his wife or mother because they were both deaf",
  "Alexander the Great made his troops eat onions as he believed it would prove their vitality",
  "Bill Russell was the first black head coach of a major league pro sports team",
  "In 1945, a seven ounce bathroom cup was the first item Tupperware marketed",
  "Central air conditioners use 98% more energy than ceiling fans.",
  "The king of hearts is the only king without a mustache",
  "Men can read smaller print than women; women can hear better",
  "Everyday, U.S. business use enough paper to circle the Earth over 20 times",
  "The Welwitschia plant can live up to 1,000 years",
  "The dromedary camel can drink as much as 100 litres of water in just 10 minutes",
  "According to the American Institute of Stress, job stress approximately costs the U.S. industry over $300 billion dollars per year",
  "It takes 72 minutes for the restaurant at the top of the CN Tower to make one revolution",
  "Coffee beans were chewed for more than 400 years before the first cup of coffee was brewed",
  "All of the Peking ducks in the United States are descendents from three ducks and one drake imported to Long Island, New York in 1873",
  "The first British ship to use the SOS distress signal was the Titanic",
  "The Spring peeper (a frog) can survive the winter season with 65% of its body water as ice",
  "Studies have shown that the scent of Rosemary can help in better mental performance and make individuals feel more alert",
  'The search engine Google got its name from the word "googol," which refers to the number one with a hundred zeros after it',
  "The Goliath beetle is about the size of your fist and can weigh as much as 3-4 ounces",
  "If you fart consistently for 6 years and 9 months, enough gas is produced to create explosion that is equal to an atomic bomb",
  "Humans have about the same number of hair follicles as a chimpanzee has",
  "Studies indicate that listening to music is good for digestion",
  "The Chihuahua was named after the Mexican state where they were discovered",
  "There are no snakes in New Zealand",
  "The most popular grown bulbs are tulips",
  "Every day the human stomach produces about 2 liters of hydrochloric acid",
  "The country of Bolivia is named after a fighter Simon Bolivar",
  "Peanuts are one of the ingredients of dynamite",
  "The first state to give the right to women to vote was Wyoming",
  "In 1949 UNICEF produced the first charity Christmas card. The picture shown on the card was painted by a seven year old girl",
  "Archeologists report that cannabis was most likely the first plant cultivated by humans. Cannabis was used for linen, paper, and garments",
  "The garfish has green bones",
  "Women who drink more than two cups of coffee a day have a higher chance of developing osteoporosis",
  "The banana was officially introduced in 1876 in the U.S. at the Philadelphia Centennial Exhibition. The bananas were wrapped in tinfoil and were sold for 10 cents each",
  "A yawn usually lasts for approximately six seconds",
  "Thirty-five percent of the people who use personal ads for dating are already married",
  "The food that people crave the most is cheese",
  "Every day more money is printed for Monopoly than the US Treasury",
  "The only animal, besides humans that can get leprosy is the Armadillo",
  "In 1894, the carnival made its debut in North America",
  "The artist Vincent Van Gogh sliced part of his ear off in madness",
  "According to Scientists, vampire bat saliva is the best known medicine for keeping blood from clotting.",
  "People from North America prefer pickles with warts, where as Europeans prefer pickles with no warts",
  "People that suffer from gum disease are twice as likely to have a stroke or heart attack",
  "Close to 50% of the water used in a home originates from the bathroom",
  "After the Krakatoa volcano eruption in 1883 in Indonesia, many people reported that, because of the dust, the sunset appeared green and the moon blue. The moon was said to appear blue for almost two years.",
  "The country with the highest consumption of chocolate per capita is Switzerland, with 22 pounds per person, per year",
  'In China, September 20 is "Love Your Teeth Day."',
  "Actor Richard Gere was considered to play the role of John McClane in the movie Die Hard. Bruce Willis played the part instead",
  "The record for the world?s worst drivers is a toss-up between two candidates: First, a 75-year-old man who received 10 traffic tickets, drove on the wrong side of the road four times, committed four hit-and-run offenses, and caused six accidents, all within 20 minutes on October 15, 1966. Second, a 62-year-old woman who failed her driving test 40 times before passing it in August, 1970 (by that time, she had spent over $700 in lessons, and could no longer afford to buy a car)",
  "Tigers have striped skin, not just striped fur",
  "Left-handed people are better at sports that require good spatial judgment and fast reaction, compared to right-handed individuals",
  "Half of a cup of figs will give you just as much calcium as half a cup of milk",
  'A "hairbreadth away" is 1/48 of an inch',
  "In 1281, the Mongol army of Kublai Khan tried to invade Japan but were ravaged by a hurricane that destroyed their fleet",
  "Walt Disney was afraid of mice",
  "Studies show that couples that smoke during the time of conception have a higher chance of having a girl compared to couples that do not smoke",
  "The reason why some people get a cowlick is because the growth of their hair is in a spiral pattern, which causes the hair to either stand straight up, or goes to a certain angle",
  "Approximately 50% of Americans admit they have ran a red light",
  "In 1755, the first Canadian post office opened in Halifax, Nova Scotia. The fist Deputy Postmaster General was American inventor Benjamin Franklin who was later dismissed for sympathizing with the American revolutionary cause",
  "Reno, Nevada is west of Los Angeles, California.",
  "On average, 90% of the people that have the disease Lupus are female",
  "Unlike other four legged mammals, kangaroos cannot walk backwards",
  "The itch from a mosquito bite can be soothed by cutting open a clove of garlic and rubbing it on the bite",
  "If you have three quarters, four dimes, and four pennies, you have $1.19. You also have the largest amount of money in coins without being able to make change for a dollar",
  "Most American car horns honk in the key of F",
  "A superstition in baseball is to never lend your bat to anyone or you will be jinxed",
  "Bats always turn left when exiting a cave",
  "Penguins can jump as high as 6 feet in the air",
  "In 1998, approximately 1.6 billion tree seedlings were planted in the United States. This amounts to about five trees per American",
  "There are species of fish that can walk on land in search of water when its water source dries up. Some can survive as long as three days on land such as the snakehead fish",
  "Racecar driver Lee Petty once left a pitstop and did a full lap at Nascar with a pit crew member still on the hood",
  "The first fashion house to be set up was in 1858 by Charles Worth. He opened his store in Paris with the idea of having pre-made gowns presented on models to his customers",
  "St. Patrick explained the Holy Trinity to King Laoghaire, using the shamrock to illustrate the trinity",
  "More twins are born in the Western world than in the Eastern world",
  "Nine egg yolks have been found in one chicken egg",
  "The record for the longest Monopoly game played in a bathtub is ninety-nine hours",
  "The flea can jump 350 times its body length, that is like a 6 foot-tall human jumping the length of 7 football fields",
  'The 1988 move "Big" which was directed by Penny Marshall was the first movie by a female director to gross over $100 million domestically',
  "When the Galileo Probe entered Jupiter's atmosphere, it was traveling at a speed of 106,000 miles per hour. This is the fastest impact speed ever achieved by a man-made object.",
  'In 1972, a gorilla by the name of "Koko" was taught ASL (American Sign Language) for the deaf. By the year 2000, the gorilla could understand approximately 2,000 English words',
  "3000 children die every day in Africa because of malaria",
  'The reason why hair turns gray as we age is because the pigment cells in the hair follicle start to die, which is responsible for producing "melanin" which gives the hair colour',
  "Approximately 125 people die in the United States from an anaphylaxis to foods each year",
  'The word "vamp" is used to describe the upper front top of a shoe',
  "Construction on the White House began in October of 1792",
  "A galactic year is 250 million Earth-years. This is the time it takes for our solar system to make one revolution around the Milky Way Galaxy.",
  "Leonardo da Vinci was dyslexic, and he often wrote backwards",
  "The male platypus has poisonous spurs on its legs",
  "Polar bears can smell seal from 20 miles away",
  "Canadians Scott Abbott and Chris Haney invented Trivial Pursuit. They were planning on playing Scrabble and realized that some of the pieces were missing so they came up with the idea of making their own game; Trivial Pursuit",
  "On average, there is about three molecules of ozone for every 10 million air molecules.",
  "A person uses approximately fifty-seven sheets of toilet paper each day",
  "The Barbie doll has more than 80 careers",
  "James Buchanan was the only unmarried president of the United States",
  "The Stanley Cup originally was only seven and a half inches high",
  "In 1991, during an attempted political coup on Russian President Boris Yelstin, food supplies had dwindled down at the parliament buildings so they ordered Pizza Hut to deliver pizzas",
  "Some people drink the urine of pregnant women to build up their immune system",
  "The five Olympic rings represent the five continents linked together in friendship",
  "Ray Kroc bought McDonalds for $2.7 million in 1961 from the McDonald brothers",
  "It is possible to lead a cow upstairs but not downstairs",
  "Shark cartilage has been used to make artificial skin for human burn victims",
  "The first person to die in the electric chair was William Kemmler, an ax murderer from New York on August 6, 1890",
  "Finland has 187,888 lakes and 179,584 islands",
  "The average adult has approximately six pounds of skin",
  "A crocodile can open and close its jaw but cannot move it side to side",
  "There are over 1,000,000 swimming pools in Florida, eventhough the ocean is no farther than 80 miles away",
  "99% of the blueberries that are produced in the United States are produced in the state of Maine",
  "On May 9, 1999 approximately 600,000 gallons of whiskey flowed into the Kentucky River during a fire at Wild Turkey Distillery in Lawrenceburg",
  'Thomas Jefferson had three achievements placed on his headstone at his request, "Here Was Buried Thomas Jefferson/Author Of The Declaration Of American Independence/Of The Statute Of Virginia For Religious Freedom/And Father Of The University of Virginia.? He never mentioned being President of the United States',
  "Humans and cows have the same gestation period, which is about nine months",
  "In the Victoria era, red tulips were a declaration of love",
  "The sport Lacrosse was initially played by Native American Indians. They played the sport to prepare for war",
  "It takes a sloth up to six days to digest the food it eats",
  "According to Scandinavian traditions, if a boy and girl eat from the same loaf of bread, they are bound to fall in love",
  "In 1796, Napoleon was only 26 years old when he took command of the French Army of Italy",
  "A bomb dropped by the Allies on Berlin during World War II killed every animal in the Berlin Zoo except the elephant, which escaped and roamed the city. When a Russian commander saw hungry Germans chasing the elephant and trying to kill it, he ordered his troops to protect it and shoot anyone who tried to kill it",
  'The expression "Tying the Knot" comes from an old Roman custom where the brides clothes were tied up all in knots and the groom was supposed to untie the knots',
  "Snake is a delicacy in China",
  "In 1999, All Nippon Airlines, had one of its jets fully decorated with Pokemon characters from nose to tail on its exterior",
  "The Dead Sea has been sinking for last several years",
  "Uranus has 27 moons",
  "Actress Sally Field was paid $4,000 a week for her role in the TV show The Flying Nun",
  "More pollution is emitted from the average home compared to the average car.",
  "The snow leopard protects itself from extreme cold when it sleeps by wrapping its 3-foot-long tail around its nose",
  "Only 4% of babies are born on their actual due date",
  "In the 1940s, the FCC assigned television's Channel 1 to mobile Services(two-way radios in taxicabs, for instance) but did not re-number theotherchannel assignments. That is why your TV set has channels 2 and up, butnochannel 1.",
  "A man named Charles Osborne had the hiccups for approximately sixty-nine years",
  "There are more Subway restaurants in Canada than there are McDonald restaurants",
  "The CN Tower, in Toronto, is the tallest freestanding structure in the world with a height of about 553 metres",
  'The term "the whole 9 yards" came from W.W.II fighter pilots in the South Pacific. When arming their airplanes on the ground, the .50 caliber machine gun ammo belts measured exactly 27 feet, before being loaded into the fuselage. If the pilots fired all their ammo at a target, it got "the whole 9 yards."',
  "Construction on the Leaning Tower of Pisa began on August 9th, 1173",
  "President Lyndon Johnson used to smoke three packs of cigarettes a day",
  "The Tibetan name for Mount Everest is Chomolungma",
  'The word "laser" stands for "Light Amplification by Stimulated Emission by radiation."',
  "In a lifetime, on average a honey bee produces 1/12th of a teaspoon of honey",
  "There are 315 species of parrot in the world",
  "The TV show Doctor Who, when it was popular, had an audience of 110 million people",
  "The cost to build the Empire State Building was $40,948,900",
  "A person who smokes a pack of cigarettes a day will on average lose two teeth every ten years",
  "Wasps that feed on ferment occasionally get drunk and pass out",
  "The largest cereal company in the world is Quaker Oats, located in Cedar Rapids, Iowa, USA",
  "The first Olympic games only had one event - a foot race",
  "Colonel Sanders traveled over 250,000 miles a year visiting various parts of his Kentucky Fried Chicken Empire",
  "Some desert snails have been known to sleep for three to four years",
  "Over 80% of the brain is water",
  "From the age of thirty, humans gradually begin to shrink in size",
  "Jackrabbits can reach a speed of fifty miles per hour and can leap as far as twenty feet",
  "There are 40 official jelly belly flavours",
  "Early sewing machines were destroyed by mobs or workers who felt their jobs were threatened by automation",
  "In 1992, the Antarctic Ozone hole was larger than the continent of North America.",
  "Someone gets divorced every ten to thirteen seconds",
  "There is a certain type of Hawk Moth caterpillar from Brazil that inflates its thorax, which makes its head look like a head of a snake when it feels it is in danger or alarmed",
  "The CIA has made a disk camera that is as big as a quarter. This gadget can take many pictures at a time when the disk is opened.",
  'The Sanskrit word for "war" means "desire for more cows."',
  'In Hong Kong, delivery times are primarily influenced by traffic conditions on elevators. It often takes drivers longer to travel vertically than horizontally, as access to elevators is so congested during "high peak" hours. This is due to the volume of people residing in high rises',
  "The ancient Greeks had a fascination with the planet Mars. They attributed the planet to Ares, their god of war, because of its red colour",
  "The only lizard that has a voice is the Gecko",
  "In Israel, religious law forbids picking your nose on Sabbath",
  "In twins, there is a great chance that one will be left handed",
  "In the 1920's, Q-Tips were invented by Leo Gerstenzang who got the idea after watching his wife clean their baby's ears with cotton stuck onto a toothpick.",
  "In the Pacific Islands when people get burns they often use a banana leaf as treatment",
  "Acorns were used as a coffee substitute during the American Civil War",
  "An airplane mechanic invented Slinky while he was playing with engine parts and realized the possible secondary use for the springs. Barbie was invented by Ruth Handler after watching her daughter play with baby dolls imagining then in grown up roles",
  "When the female grasshopper lays eggs, she covers her eggs with a pasty liquid that protects the eggs throughout the winter",
  "The longest recorded duration of a total solar eclipse was 7.5 minutes.",
  "On average, an American makes three pounds of garbage in a day",
  "Even if you eat food standing on your head, the food will still end up in your stomach",
  "Only one person in two billion will live to be 116 or older.",
  "The most common name in the world is Mohammed",
  "Apple seeds are poisonous as they contain a cyanide compound",
  'The word breakfast was coined due to the fact that after sleeping for hours, we are "breaking our fast."',
  "The cardigan was originally made to be a military jacket made of knitted wool",
  "The month of December is the most popular month for weddings in the Philippines",
  'The deepest cave in the world is the "Lamprechtsofen-Vogelshacht" cave which can be found in Salzburg, Austria. The cave is 5,354 feet deep',
  "The capital of Vermont, Montpelier is the only state capital in the United States that does not have a McDonalds",
  "The longest engagement lasted 67 years, and the couple ended up marrying when they were 82 years old",
  "Our eyes are always the same size from birth, but our nose and ears. never stop growing",
  "Milk and cheese can aid in the reduction of tooth decay",
  "On average, a strawberry has 200 seeds on it",
  "Coconuts kill more people in the world than sharks do",
  "The average person spends two weeks of their life kissing",
  "Research has indicated that indoor pollution is 10 times more toxic than outdoor pollution",
  "Eating a banana at night can help in falling asleep",
  "The stapler was invented in Spring Valley, Minnesota.",
  "The first television newscaster was Kolin Hager, who used to broadcast farm and weather reports in 1928",
  "Pixie, a Siberian Husky, gave birth to 7 puppies, one of which was bright green",
  "Back in 1953, it took 27 hours to make one Marshmallow Peep. Now it takes only six minutes",
  "On average, an ear of a corn has 16 rows and approximately 800 kernels",
  "The green ring that is formed around the yolk of eggs that have been cooked too long is formed by the chemical reaction from the iron in the yolk and the sulphur in the white part of the egg",
  "The silk that is produced by spiders is stronger than steel",
  "The first president to have a picture taken was John Quincy Adams",
  "Some brands of toothpaste contain glycerin or glycerol, which is also an ingredient in antifreeze",
  "1 in 2000 babies are born with a tooth that is already visible",
  "It was during World War II that clothes with elastic waists were introduced. This is because the metal used in zippers was badly needed for the war",
  "In 1902, the game table tennis was brought to the U.S. from Europe by Parker Brothers",
  "Hershey's Kisses are called that because the machine that makes themlooks like it's kissing the conveyor belt.",
  "The fat from sheep, which is called tallow can be used to make soap and candles",
  "Next to bone marrow, hair is the fastest growing tissue in the human body",
  "Sigmund Freud had a morbid fear of ferns",
  "When playing competitive darts the player must be 7 feet 9 1/4 inches back from the dartboard. Also the board must be 5 feet 8 inches above the floor",
  "In England, the Speaker of the House is not allowed to speak",
  "Earthworms have 5 hearts",
  "If all the gold sitting in the oceans and seas were mined, every person on this plant would get about 20 kilograms of gold each.",
  "To make an espresso 42 coffee beans are needed",
  'The oil that is found in poison ivy is called "urushiol."',
  "Of all the days of the week, the most popular day for people to eat ice cream is Sunday",
  "The first museum in Moscow that was set up in 1791 was the Natural History Laboratory at Moscow University. This later was changed to the Zoological Museum",
  'A surfer once sued another surfer for "stealing his wave." The case was thrown out because the court was unable to put a price on "pain and suffering" endured by the surfer watching someone else ride "his" wave',
  "Many people in parts of China eat insects. Some common insects are bean worms, scoprions, and locusts",
  "The largest dog in the world is the Irish Wolfhound",
  'Ernest Vincent Wright wrote a fifty thousand-word novel, "Gadsby," without any word containing the letter "e."',
  "The projection light used for IMAX theaters can be seen from space.",
  "The human liver performs over 500 functions",
  "Ballroom dancing is a course at Brigham Young University in Utah",
  'The word "maverick" came into use after Samuel Maverick, a Texan, refused to brand his cattle. Eventually any unbranded calf became known as a Maverick',
  "Finnish folklore states that when Santa comes to Finland to deliver gifts, he leaves his sleigh behind and rides on a goat named Ukko instead",
  "More than $1 billion is spent each year on neck ties in the United States",
  "In the 18th century, potatoes were given out as a dessert. They were served in a napkin, salted and hot",
  "The only poisonous birds in the world are the three species of Pitohui. The Hooded Pitohui from Papua New Guinea is the most deadliest out of the three",
  "Pretzels were originally invented for Christian Lent. The twists of the pretzels are to resemble arms crossed in prayer",
  "The American Airlines Center in Dallas has more toilets per capita than any other sports and entertainment venue in the country",
  "After 8 months, babies are more likely to get a diaper rash",
  "The first modern toothbrush was invented in China. Its bristles came from hogs hair or the mane of a horse that were then put into ivory handles",
  "The New Zealand Kiwi bird cannot fly",
  "66% of wedding cards are hand delivered by people",
  "Heavier lemons produce more, and tastier, juice",
  "The leading cause of poisoning for children under the age of six in the home is liquid dish soap",
  "The same amount of calories are burned by doing 6 sessions that are 5 minutes each of an activity and doing 1 session of that activity for 30 minutes",
  "General William Booth is the founder of the Salvation Army",
  "Iguanas can stay under water for up to thirty minutes",
  "The fastest flying butterfly is the Monarch, which has been clocked with a speed as high as 17 miles per hour",
  "Egyptian pyramid builders used to eat a lot of garlic because they thought it would increase their strength",
  "The average office document gets copied 19 times",
  "In just the first 56 days of life, the larva of the polyphemus moth eats about 86,000 times its birthweight",
  "Every hour one billion cells in the body must be replaced",
  "American actor Jack Nicholson, and American singer Bobby Darrin were raised believing their grandmothers were their mothers and their mothers were their older sisters",
  "The first Ford cars had Dodge engines",
  "The average height of an NBA basketball player is 6 feet 7 inches",
  "One in five Americans move homes every year",
  "The chocolate chip cookie was invented in 1933",
  "The capital of Burkina Faso is Ouagadougou",
  "A catfish has about 100,000 taste buds",
  "The Liberty Bell was the first mechanical slot machine, which was invented by Charles Fey, a car mechanic in 1895.",
  "A Russian man who wore a beard during the time of Peter the Great had to pay a special tax",
  "The silkworm moth has lost the ability to fly ever since it has been domesticated",
  "The first cheerleaders in the U.S. were men",
  'The name Jeep came from the abbreviation used in the army for the "General Purpose" vehicle, G.P',
  "The odds of having quadruplets are 1 in 729,000",
  "In 1965, the price for an issue of TV Guide was 15 cents",
  "In 1565 In St. Augustine, Florida the first orange trees were planted",
  "Nose prints are used to identify dogs, much like humans use fingerprints",
  "In the United States, six tubs of Cool Whip, a brand of whipping cream, are sold every second",
  "The most popular chocolate bar in the United Kingdom for the last 15 years has been Kit Kat",
  'White-Out was invented by Bette Nesmith Graham, who is the mother of Michael Nesmith from the "The Monkees."',
  "There are over 2,000 different types of cheese in the world",
  "The giant squid has the largest eyes in the world",
  "Owls swallow their prey whole because they have no teeth. After approximately 12 hours they cough up the feathers, bones, and fur in a shape of a football pellet",
  "Historically, a blue ribbon has been awarded for first prize",
  "Seventy-one percent of households report they have at least one snorer. Forty-five percent of those surveyed admit they snore, 35% said their partner snores, 12% said their child snores and 9% reported their pet snores",
  'The original meaning of the word grocer was referring to a person who traded food in wholesale. These people would usually sell in large quantities, or by the "gross."',
  "Research indicates that mosquitoes are attracted to people who have recently eaten bananas",
  'Actress Michelle Pfeiffer was the first choice to play Clarice Starling in the movie "Silence of the Lambs." She turned down the role because she found it too scary',
  "The White House has 35 bathrooms, 3 elevators, 132 rooms, and 412 doors in it",
  "Due to the deforestation of the forests in North China, over one million tons of sands blows into Beijing from the Gobi desert. It sometimes causes the sky to turn yellow.",
  "Cows are able to hear lower and higher frequencies better than human beings",
  "Approximately 60% of the water used by households during the summer is used for watering flowers, and lawns",
  "The largest diamond that was ever found was 3106 carats.",
  "In 1970, Chip maker Intel purchased a pear orchard to build their corporate headquarters on",
  "The mating call of a male toadfish, who are underwater, is so loud that it can be heard by humans above water",
  "The most popular jelly belly jellybean flavour is buttered popcorn",
  "The Nike swoosh was invented by Caroline Davidson back in 1971. She received $35 for making the swoosh. The first shoe with the swoosh was introduced in 1972",
  "Slaves under the last emperors of China wore pigtails so they could be picked out quickly",
  "A crocodile cannot stick its tongue out",
  "Kiwis are the only known bird to have nostrils located at the tip of their beak",
  "An adult esophagus can range from 10 to 14 inches in length and is one inch in diameter",
  "A squash ball moving at 150 kilometers per hour has the same impact of a .22 bullet",
  "Telephonophobia is the fear of telephones",
  'The word alligator comes from the Spanish word El Lagarto, which means "The Lizard."',
  'While still in college, Bill Gates and Paul Allen once built a special purpose machine called "Traff-O-Data." It was a machine that would analyze information gathered by traffic monitors. They never found any buyers.',
  "The citric acid found in lemon juice is said to be able to dissolve a pearl",
  'Robert Southey wrote the story "Goldilocks and the Three Bears" in 1834',
  "The tallest woman in the world is American Sandy Allen who is 7 feet 7 inches",
  "American Airlines saved $40,000 in 1987 by eliminating one olive from each salad served in first-class",
  "Astronauts get taller when they are in space",
  "Only 5 to 10 percent of cheetah cubs make it to adulthood",
  "Dentyne gum was invented in 1899 by a druggist from New York named Franklin V. Canning",
  "It takes about three hours for food to be broken down in the human stomach",
  "When former Texas Governor James Hogg was on his deathbed he made a special request that a pecan tree be planted at the head of his grave instead of a tombstone. The governor passed away on March 2, 1906, which is Texas Independence Day. The pecan tree is now the state tree of Texas",
  "In a year, there are 60,000 trampoline injuries that occur in the U.S",
  "There is an organization called SCROOGE in Charlottesville, Virginia that stands for Society to Curtail Ridiculous, Outrageous, and Ostentatious Gift Exchanges. This was formed to keep gift giving affordable and simple",
  "The first World Series baseball playoffs occurred in 1903",
  "Archipelago is the word to describe a large group of islands that are located close together",
  "The life expectancy of a garbage disposal is about 5 to 10 years",
  'In the original movie "101 Dalmatians," there are exactly 6,469,952 spots on all 101 Dalmatians as they are shown in 113,760 frames of the film combined',
  "The average North American car contains 300 pounds of plastics",
  "A person who is a specialist in wine making is called an oenologist",
  "You can only smell 1/20th as well as a dog",
  "The number one cause of rabies in the United States are bats",
  'The music for "The Star Spangled Banner" comes from a British drinking song named "Anacreon."',
  '27 percent of U.S. male college students believe life is "a meaningless existential hell." (big surprise, eh?)',
  "Close to fifty percent of the bacteria in the mouth lives on the surface of our tongue",
  "Less than 1% of the women in the world will ever be able to wear a diamond that is the size of a carat or more",
  "Ketchup originated in China as a pickled fish sauce called ke-tsiap",
  "An ostrich's eye is bigger that it's brain.",
  "In Britain, The Red Lion is the most common name for a pub",
  "In 1997, the record for the highest skydive by a dog at 4,572 feet was established by a dog named Brutus",
  "The majority of burglaries occur during the daytime when people are not home",
  "Traditionally, wild cabbage was used as an aphrodisiac",
  "Tiger Woods was introduced to golf at nine months of age by his father",
  "A person will burn 7 percent more calories if they walk on hard dirt compared to pavement",
  "It would take 29 million years for a car travelling 100 miles per hour to reach the nearest star",
  "Blue Jays can imitate the calls of hawks",
  "There are over three trillion craters on the moon, with some being having a diameter over three feet",
  'In India, a 9-year-old girl was "married" to a stray dog, which tribal custom requires in order to protect a child whose first tooth appears on the upper gum',
  "There is now an ATM at McMurdo Station in Antarctica, which has a winter population of two hundred people",
  "In Canada, men are three times more likely than women to have seen a doctor in the last year",
  "The most expensive spice in the world is saffron",
  "In one night, an adult hippopotamus eats approximately 150 pounds of grass",
  "The U.S. paid Russia $7.2 million for Alaska in 1867",
  "Cows can detect odors up to five miles away",
  "There are about 125 million multiples (twins, triplets, etc.) worldwide",
  'Arthur Giblin was the inventor of the first "flushable" toilet',
  "Consuming chocolate was once considered a sin during the 16th and 17th century. During that time it was provided in the form of a drink and since drinking wine during lent was a sin, so was drinking chocolate",
  "Approximately 40% of the states in the U.S. have severe, or extreme pollution problems",
  "Wendel Clark holds the record for the longest span between NHL All-Star appearances, with 13 years (1986-1999)",
  "Ancient Egyptians believed that onions would keep evil spirits away",
  "Dill seeds are so small that approximately 10,000 dill seeds would be required to make an ounce",
  "To make one pound of whole milk cheese, 10 pounds of whole milk is needed",
  "If all the insects in the world were put on a scale, they would out weigh all creatures",
  "Women smile more than men do",
  "A ripe cranberry will bounce. Another name for a cranberry is bounceberry",
  "Termites work 24 hours per day -- they do not sleep",
  "The Romans used to clean themselves with olive oil since they did not have any soap. They would pour the oil on their bodies, and then use a strigil, which is type of blade, to scrape off any dirt along with the oil",
  "The act of stretching and yawning is referred to as pandiculation",
  'In the 1960 movie "Psycho" by Alfred Hitchcock, chocolate syrup was used to show the blood in the shower scene',
  "Carolyn Shoemaker, famous astronomer, has discovered 32 comets and approximately 300 asteroids",
  "The longest fangs of a snake are found on the Gaboon Viper (Bitis gabonica), and can reach over 2 inches in length",
  "Once a human reaches the age of 35, he/she will start losing approximately 7,000 brain cells a day. The cells will never be replaced",
  "The only king without a moustache in a deck of cards is the king of hearts",
  "Approximately 100,000 people get married in Las Vegas each year",
  "Amish people do not believe in the use of aerosal air fresheners",
  'Coca-cola used to use the slogan "Good to the last drop," in 1908. This slogan was later used by Maxwell House',
  "The blind cavefish is born with eyes, but they fall off as the fish grows",
  "In ancient Egypt, Priests plucked EVERY hair from their bodies including their eyebrows and eyelashes",
  "The Indian election in 1984 was the largest election of any country. Over 379,000,000 voters were eligible to vote at over 480,000 polling stations",
  "A single chocolate chip gives enough energy to a human being to walk 150 feet",
  "There are 54 bones in your hands including the wrists",
  'The name for Oz in "The Wizard of Oz" was thought up when the creator, Frank Baum, looked at his filing cabinet and saw A-N, and O-Z, hence "Oz."',
  'The town of Churchill, Manitoba, located in Canada, is known as the "Polar Bear Capital of the World"',
  'Amtrak is the combination of the words "American" and "Track"',
  "On average, an American relocates 11 times in their life",
  "Fires onland generally move faster uphill than downhill",
  'The cartoon character Popeye was actually based on a real person named Frank "Rocky" Fiegel who was a tough guy who was quite similar to Popeye physically',
  "Frisbee got its name from William Russel Frisbee, who was a pie baker. He used to sell his pies in a thin tin pan, which had Frisbee written on it. When Walter Frederick Morrison thought of the idea of making saucer like disks to play catch, he visited the campus of Yale and noticed people there were using the pie pan to play catch so he therefore renamed his invention to Frisbee",
  "Some arthritis medications contain gold salts, which is used as an anti-inflammatory",
  "Lemon juice can aid in reducing the swelling caused by insect bites",
  "LSD is made from lysergic acid, which is found in ergot, a type of fungus",
  'DC-10, the name of an airplane stands for "Douglas Commercial."',
  "In approximately 18 months, the papaya tree can grow to be 20 feet tall",
  "Parrots cannot eat chocolate because it is poisonous to their body",
  "Americans are responsible for generating roughly 20% percent of the garbage in the world",
  "Termites are roasted and eaten like popcorn in South Africa",
  "The official state tree of Illinois is The White Oak",
  "It takes 3,000 cows to supply the NFL with enough leather for a year'ssupply of footballs.",
  "In 1971, the postal code was introduced in Ottawa, Ontario",
  "The tridacna clam can grow up to four feet long and weigh up to 500 pounds",
  'The state that has the most diners in the world is New Jersey, which is referred to as the "Diner Capital of the World."',
  "Approximately 1-2 calorie are burned a minute while watching T.V",
  "The first recipe for a lasagna type dish was found to be from a British cookbook in the 14th century. Therefore, Italians were not the first ones to come up with the popular dish as believed",
  "Only one person in two billion will live to be 116 or older",
  "If an identical twin grows up without having a certain tooth, the other twin will most likely also grow up with that tooth missing",
  "Albert Einstein was offered the presidency of Israel in 1952, but he declined",
  "According to legend, tea originated in China when tea leaves accidentally blew into a pot of boiling water",
  "When you sneeze, all your bodily functions momentarily stop, including your heart",
  "Kite flying is a professional sport in Thailand",
  "Urine from men?s public urinals was sold as a commodity in Ancient Rome. It was used as a dye and for making clothes hard",
  "All 50 states are listed across the top of the Lincoln Memorial on the back of the $5.00 bill",
  "1 out of 350,000 Americans get electrocuted in their life",
  'New Mexico is known as the "Land of Enchantment."',
  "In 1890, Scott Paper produced the first toilet paper to be available on a roll",
  "An elephant in the wild can eat anywhere from 100 - 1000 pounds of vegetation in a 16 hour period",
  'Some of the other names that were thought of for the dwarfs in the Disney movie "Snow White" were Awful, Dirty, Shifty, Hotsy, and Jumpy',
  "In the U.S., over 35 million people have used some sort of illegal drug in the last year",
  "96% of candles that are purchased are by women",
  "The oldest bird on record was Cocky, a cockatoo, who died in the London Zoo at the age of 82",
  "A cow averages 40,000 jaw movements a day",
  "The reason the soft drink Dr Pepper is called that is because the inventor Wade Morrison named it after Dr. Charles Pepper who had given him his first job",
  "Annually 17 tons of gold is used to make wedding rings in the United States",
  "Sex acts like a natural antihistamine, in can clear up a stuffy nose",
  "Women on average live seven years longer than men do",
  'A British term for slot machine is "fruit machine" or "one-armed bandit."',
  "Most dust particles in your house are made from dead skin",
  "Even though the rose does not bear any fruit, the rose hips have more Vitamin C than most vegetables and fruits",
  "In 1946 Danon Yogurt were the first to add fruit to commercially produced yogurt in U.S",
  "The first domain name ever registered was Symbolics.com on March 15, 1985.",
  "American Airlines saved $40,000 in 1987 by eliminating one olive from each salad served in first class",
  'The first flavour of a cheese ball was called "Cheddy Blue."',
  "Over 500 million gallons of Kool-Aid drink are consumed each year",
  "The waste produced by one chicken in its lifetime can supply enough electricity to run a 100-watt bulb for five hours",
  "Hydrogen solid is the most dense substance in the world, at 70.6g/cc",
  "Missouri has been to most NCAA tournaments than any other college without reaching the final four",
  "The hump of a camel can weigh up to 35 kilograms",
  "Lake Malawi has the largest number of fish species in the world",
  "The country of Fiji is made up of 332 islands",
  "The first Labor Day holiday was celebrated on Tuesday, September 5, 1882, in New York City",
  'The word "sophomore" means "sophisticated moron."',
  "Jim Bristoe, an American, invented a 30-foot-long, 2-ton pumpkin cannon that can fire pumpkins up to five miles.",
  "There are about 61,300 pizza restaurants in the United States of America",
  "To tell if a egg is fully cooked or raw, just spin it. If the egg wobbles then it is still raw, and if it easily spins it is fully cooked",
  'Used in art the word "sfumato" refers to the subtle blending of an outline by gradually blending one tone into another',
  "There is a species of bird, Antpitta avis canis Ridgley, that barks like a dog",
  "The flu pandemic of 1918 killed over 20 million people",
  "Approximately 20% of Americans have a passport",
  "The Nobel prize was first awarded in 1901",
  "The reason why milk is white is because it contains a protein called Casein, which is white. Milk also contains fat, which is also white",
  "After twenty-seven years, Betty Rubble made her debut as a Flintstones Vitamin in 1996",
  "When telephone companies first began hiring telephone operators, they chose teenage boys for the job. They switched to women because the teenage boys were wrestling instead of working and pulling pranks on callers",
  "In a lifetime, the heart pumps about one million barrels of blood",
  "The Sears Tower in Chicago contains enough steel to build 50,000 automobiles",
  'The first words that Thomas A. Edison spoke into the phonograph were, "Mary had a little lamb."',
  "There are 400 species of bacteria in the human colon",
  "On average, 100 people choke to death on ballpoint pens every year.",
  "In the 20th century, over three million people have died from earthquakes",
  "It is impossible to sneeze with your eyes open",
  "People still cut the cheese shortly after death",
  "In ancient Egypt, the only person who was allowed to wear cotton was the High Priest",
  "Blueberries have more antioxidents than any other fruit or vegetables",
  "The Mount Horeb Mustard Museum which is located in Wisconsin has the biggest collection of prepared mustards. They have approximately 4,000 different jars and tubes from all over the world",
  "Bananas trees are not really trees. They are considered to be giant herb plants",
  "Sponge Candy was invented in Buffalo, NY",
  "When the volcano Krakatoa off the Java islands exploded in 1883, it was so loud that it woke some people up in South Australia",
  "Pearls are rarely found in North American oysters",
  "The average cocoon contains about 300-400 metres of silk",
  "Lake Nicaragua boasts the only fresh-water sharks in the entire world",
  "Boxing champion Gene Tunney taught Shakespeare at Yale University",
  "The most popular pickle is the Dill pickle",
  "Diabetes is the fourth leading cause of death in the U.S., accounting for about 180,000 deaths per year",
  "Every year approximately 3,000 people choke to death",
  "Cow is a Japanese brand of shaving foam",
  "The fastest running bird is the Ostrich, which has been clocked at 97.5 kilometres per hour",
  "When the divorce rate goes up in the United States, toy makers report that the sale of toys also rise",
  "Q-Tip Cotton Swabs were originally called Baby Gays",
  "The Pacific island of Tonga once issued a stamp that was banana shaped",
  "A mole can dig a tunnel three hundred feet long in a single night",
  "The glue on Israeli postage stamps is certified kosher",
  "The only commercial aircraft that is able to break the sound barrier is the Concorde.",
  "U.S. Postal Service processes 38 million address changes each year",
  'In 1984, Ronald Reagan declared the month of July to be "National Ice Cream Month."',
  "The small intestine in the human body is about 2 inches around, and 22 feet long",
  "In 1905, the first pizzeria in the U.S. opened in New York City",
  "In the early nineteenth century some advertisements claimed that riding the carousel was good for the circulation of blood",
  'For the blockbuster movie "The Terminator," O.J. Simpson was considered to play the role of the Terminator, but producers did not choose him as they thought he would not be taken seriously',
  "The actor who played the T-1000 in Terminator 2 (Robert Patrick) and the lead singer of Filter are brothers",
  "The sole purpose of a drone bee is to mate with the queen bee",
  "There are more plastic flamingos in America than real ones",
  "Walt Disney had a fear of mice",
  "Cleopatra married two of her brothers",
  "In an average lifetime, people spend four years traveling in cars and six months waiting for red light to turn green",
  "At one time, pumpkins were recommended for removing freckles",
  "In just one drop of liquid, 50 million bacteria can be present",
  "The Montreal Canadians hockey team has won the most Stanley Cups with 24",
  "Nylon is a man-made fibre that is made from coal and petroleum",
  "When the First Lady, Eleanor Roosevelt, received an alarming number of threatening letters, soon after her husband became President at the height of the Depression, the Secret Service insisted that she carry a pistol in her purse",
  "Swiss engineer George de Mestral, who got the idea after noticing burrs were sticking to his pants after his regular walks through the woods, invented Velcro.",
  "Bees can communicate with other bees by dancing. Their dance can alert other bees as to which direction and the distance nectar and pollen is located",
  "The steepest street in the world is Baldwin Street located in Dunedin, New Zealand. It has an incline of 38%",
  "One billion pounds of pasta would need approximately 2,021,452,000 gallons of water to cook it. This is equivalent to 75,000 Olympic-size swimming pools",
  "Nutmeg is extremely poisonous if injected intravenously",
  "The average Super Bowl party has 18 people",
  "An ant can detect a movement through 5 centimeters of earth",
  "One out of 200 women is colorblind",
  "On average, the rainfall across the Amazon is 7 feet annually.",
  "Passion fruits have a tranquilizing effect on the body",
  "It is physically impossible for pigs to look up into the sky",
  "A snail can sleep for 3 years",
  '"Dreamt" is the only English word that ends in the letters "mt"',
  "There is a large brass statue of Winnie-the-Pooh in Lima, Peru",
  "In 1982, Larry Walters tied 24 weather balloons to his lawn chair in Los Angeles and climbed to an altitude of 16,000 feet",
  "Centuries ago in India, a person could get their nose chopped off for breaking the law",
  "The sound of E.T. walking was made by someone squishing her hands in Jello",
  "The total number of steps in the Eiffel Tower are 1665",
  "The amount of blood a female mosquito drinks per serving is five millionths of a liter",
  "An adult porcupine has approximately 30,000 quills on its body, which are replaced every year",
  'The name for Ivory Soap was inspired by a verse from the Bible. Harley Proctor got though of the name when the minister read from Psalms 45:8, "All thy garments smell of myrrh and aloes and cassia, out of the ivory palaces whereby they have made thee glad."',
  "A survey done by Clairol 10 years ago came up with 46% of men stating that it was okay to color their hair. Now 66% of men admit to coloring their hair",
  'The name of the squiggly line "~" is called a tilde',
  "In 1747, the first American mention of the Christmas tree occurred. However, it was a not a tree but instead a pyramid made out of wood and decorated with apples and evergreen boughs",
  "There are more Barbie dolls in Italy than there are Canadians in Canada",
  "The study of ants is called Myrmecology",
  "In the United States, you are more likely to be killed by a bee sting than a shark attack",
  "Coca-Cola was the first soft drink to be consumed in outer space",
  "Frozen food can be just as nutritious as fresh food",
  "Fourteen people die each day from asthma in the United States",
  "The average amount of time spent kissing for a person in a lifetime is 20,160 minutes",
  "The human body has approximately 37,000 miles of capillaries",
  "The most expensive shoes in the world are ruby slippers located in Harrods in London, which cost $1.6 million, has a full time security guard. The shoes are made from platinum thread and has 642 rubies in them. It took over 700 hours to produce the shoe",
  "Only 55% of all Americans know that the sun is a star",
  "The city of Denver was originally chosen to host the 1976 Winter Olympics, but had to withdraw because Colorado voters rejected to finance it",
  "The longest bout of sneezing recorded was by Donna Griffith. It began in January 13 1981 and continued until September 16 1983 and lasted for 978 days",
  "The Taj Mahal, located in Agra, India, was actually built for use as a tomb by Mogul ruler Shah Jehan for his wife, Arjuman Banu Begum",
  'In 1943, the July issue of "Transportation Magazine" had an article entitled "1943 Guide to Hiring Women."',
  "Spotted skunks do handstands before they spray",
  "A dime has 118 ridges around the edge",
  "Scientists with high-speed cameras have discovered that rain drops are not tear shaped but rather look like hamburger buns.",
  "Ancient Egyptian women used to wear perfume cones made of wax that would melt in the heat letting out a nice fragrance",
  'Mardi Gras means "Fat Tuesday." This is the festival that New Orleans, Louisiana is famous for having every year',
  "There was once a fish caught in Delaware Bay with a watch still ticking inside",
  "In Singapore, it is illegal to sell or own chewing gum",
  "During the female orgasm, endorphines are released, which are powerful painkillers. So headaches are in fact a bad excuse not to have sex",
  "Donkeys kill more people annually than plane crashes",
  'The nut "filbert" got its name from St. Philbert which is celebrated on August 22nd, which is also when the nut matures',
  "Every time Beethoven sat down to write music, he poured ice water over his head",
  "Some species of dolphin sleep with one eye open",
  "The chewing gum Juicy Fruit has 10 calories. This is approximately the same as a bite of whole wheat bread",
  "In ancient Egypt, doctors used jolts from the electric catfish to reduce the pain of arthritis",
  "In order to scare away predators, Giant petrels, a type of seabird, throw up all over the intruder",
  "Elvis Presley used to be a truck driver before he started singing",
  "The average cow produces about 2,305 gallons of milk each year",
  "Former U.S. president Ronald Reagan worked as a lifeguard in his youth at a beach near Dixon, Illinois and saved over 77 lives",
  "In 2000, there were 1,579,566 drug arrests in the United States. Of those, close to half were for marijuana",
  "Just like fingerprints, every cats nose pad is different",
  "Popeye is 34 years old, weighs 158 lbs, and is 5 feet 6 inches tall",
  "Researchers have shot footage of Orcas (killer whales) attacking and killing great white sharks",
  "The three most valuable brand names on earth: Marlboro, Coca Cola, and Budweiser, in that order",
  "One bushel of wheat can make enough sandwiches that you could eat three sandwiches a day for over six months",
  "In 1992, approximately 750 deaths occurred in the United States due to workplace violence",
  'In the movie "Babe", the piglet was played by over 30 different piglets they outgrew the part so quickly during the production of the film',
  "Research indicates that people prefer the colour blue for their casual clothing",
  "The leading cause of deaths for children between the ages of 1 and 4 are motor vehicle crashes",
  'The first toilet being flushed in a motion picture was in the movie "Psycho."',
  "The human brain has about 100,000,000,000 (100 billion) neurons",
  "One acre of wheat can produce enough bread to feed a family of four people for about ten years",
  "Queen Victoria used marijuana, to help relieve menstrual cramp pain",
  "On a ship a toilet is called a head",
  "About 1 in 5,000 North Atlantic lobsters are born bright blue",
  "Approximately 10.5 gallons of water is used in a dishwasher. Washing the dishes by hand can use up to 20 gallons of water",
  "The thing that hangs from the top of the beak of a turkey is called the snood",
  "Ticks can be as small as a grain of rice and grow to be as big as a marble",
  "An American chews an average of 300 sticks of gum in a year",
  "Most cows give more milk when they listen to music",
  "Giant flying foxes, which are a type of bat, that live in Indonesia have wingspans of nearly six feet",
  "Heavier lemons contain more and tastier juice",
  "Since 1950, over 230 million eggs of Silly Putty have been sold",
  "Oral-B is a combination of oral hygiene and the letter B, which stands for the word better",
  "Frank Wathernam was the last prisoner to leave Alcatraz prison on March 21, 1963",
  "A blink lasts approximately 0.3 seconds",
  "In 1903 Mary Anderson invented the windshield wipers",
  "Both Thomas Jefferson and Jimmy Carter, U.S. presidents, were peanut farmers at one time",
  "A cow releases about 125 gallons of gas per day",
  "The Apollo 17 crew were the last men on the moon",
  "Spartacus led the revolt of the Roman slaves and gladiators in 73 B.C",
  "The Mexican version of the Tooth Fairy is known as the Tooth Mouse, which takes the tooth and leaves treasures in its place",
  "In a day the blue whale calf drinks approximately 130 gallons of milk",
  "Right-handed people live, on average, nine years longer than left-handed people do",
  "All racehorses in the U.S. celebrate their birthday on January 1st",
  "A person would have to drink more than 12 cups of hot cocoa to equal the amount of caffeine found in one cup of coffee",
  "The oldest documented footwear found was a 8,000 year-old sandal found in a cave located in Missouri, USA",
  "Broccoli was first introduced into France during the royal marriage of Catherine de Medici to Henry II of France",
  "By federal law, for a noodle to actually be a noodle it must have 5.5 percent egg solids in it, otherwise it cannot be called a noodle",
  "The first female guest host of Saturday Night Live was actress Candace Bergen",
  "The human heart beast roughly 35 million times a year",
  "People that use mobile phones are 2.5 time more likely to develop cancer in areas of the brain that are adjacent to the ear they use to talk on the mobile phone",
  "Turkeys can have heart attacks. When the Air Force was conducting test runs and breaking the sound barrier, fields of turkeys dropped dead because of heart attacks",
  "The act of sneezing is referred to as sternutation",
  "The average medium size piano has about 230 strings",
  "A study revealed that men that were born with a low birth weight were less likely to get married",
  "It takes about 63,000 trees to make the newsprint for the average Sunday edition of The New York Times",
  "On average, you would need 12.5 gallons of milk to make one gallon of ice cream",
  "A leech can gorge itself up to a maximum of five times its body weight",
  "There are places in Saskatchewan called Elbow, Eyebrow, and Drinkwater",
  "A butterfly has to have a body temperature greater than 86 degrees to be able to fly",
  "The beeswax that is produced by Honey bees comes from eight paired glands that are located on the underside of their stomach",
  "People in low-income homes spend 50% more time playing video games than people in high-income homes",
  'Lighthouse keepers were nicknamed "wickies" because they tended the lamps wick',
  "Taco Bell serves over 35 million consumers each week in the USA",
  "During the Roman times, people used urine, called lotium in Latin, as a hair product",
  "There are approximately 90 people that have been frozen after their death.",
  "The smallest will ever written was 3.8 cm in diameter. It had 40 words written on it and was signed by two witnesses",
  "The length of a human esophagus is 25 centimeters",
  "In 1942 the Jello company introduced Cola flavored jello, which only lasted a year",
  "Males account for 60% of toy injuries that occur in the U.S",
  'The company "Sony" was originally called "Totsuken." They felt the name "Sony" would be easier to pronounce. The name was invented by a cross between the name "sonus" and "sonny." The name sound and sonic are derived. Sonny was used to represent a young man or boy, which would show a energetic young company',
  "The Red Cross is called the Red Crescent in Arab countries",
  "The Olympics were originally held for the Greek god Zeus",
  "The oldest inhabited house in Scotland is the Traquair Castle. The castle has had 27 kings as visitors",
  "Four billion pounds of watermelon were grown in the United States in 1999",
  "Wham-O manufactured twenty-thousand hula-hoops a day at the peak of hula-hoop popularity in 1958",
  'JELL-O was declared The "Official State Snack" of Utah in January 2001',
  "Elvis Presley was obsessed with brushing his teeth",
  "When the Statue of Liberty was moved from France to the United States, 214 crates were used to transport it. The Statue was also reduced to 350 pieces",
  "There are approximately 2000 thunderstorms that are active at the same time which results in 100 lightning flashes a second.",
  "In-vitro babies are born in Australia more than any other country in the world",
  "Uranus? winter and summer seasons last the equivalent of 21 Earth years",
  "More people die from eating sharks then from being eaten by them. This is due to a poison in shark meat",
  "The murder rate in the United States is about four times greater than in Japan. In Japan, no private citizen can buy a handgun legally",
  "The rarest chocolate bar in the world is the Porcelana bar. There are only 20,000 of these bars produced a year, and they sell for $90 per pound",
  'The reason why locusts swarm are because when they are in groups, a "hot-spot" behind their hind legs is stimulated, which in turn causes their destructive nature. A large swarm of locusts can eat eighty thousand tons of corn in a day',
  "There are an equivalent number of cows and people in Friesland, Netherlands",
  "Centipedes always have an uneven pairs of walking legs",
  "A chicken once had its head cut off and survived for over eighteen months, headless",
  'The largest diamond found in the United States was a 40.23 carat white diamond. It was found in 1924 and nicknamed the "Uncle Sam."',
  "Following directions off the Internet and chemicals obtained from a mail order company, a team of U.S. scientists created an identical copy of the polio virus.",
  "Every day, the Hubble telescope transmits enough data to fit 10,000 standard computer disks",
  "The average number of people that go to a party for the Super Bowl is 17",
  "The amount of Kit Kat chocolate bars that are made at the York factory every 15 minutes are enough to outstack the Eiffel Tower",
  'The skin of a shark is made up of "tiny teeth" which are called dermal denticles',
  "The strongest gust of wind was recorded at the Mount Washington Observatory on April 12th, 1934, and measured 231 miles per hour.",
  "The company Chanel claims that every 30 seconds, somewhere in the world, a bottle of Chanel No 5 is sold",
  "In their lifetime, house cats spend approximately 10,950 hours purring",
  "The reason why bubbles are round is because this is the most efficient shape that the soap film can take for the amount of air trapped inside",
  "It is very common for babies in New Zealand to sleep on sheepskins. This is to help them gain weight faster, and retain their body heat",
  "From 1526 to 1707, the first six Mogul emperors of India ruled in unbroken succession from father to son",
  "A one kilogram packet of sugar will have about 5 million grains of sugar",
  "Bats emit ultrasonic sounds to communicate with each other",
  "Rats can survive up to 14 days without any food",
  "Canola oil is actually rapeseed oil but the name was changed in Canada for marketing reasons",
  "Three consecutive strikes in bowling is called a turkey",
  "In a year, about 90 million jars of Skippy Peanut Butter are sold. This works out to three jars sold every second",
  "In a lifetime, an average man will shave 20,000 times",
  "The Pentagon has 284 restrooms",
  "From 1967-1976, the town of Tororo located in Uganda had thunder 251 out of the 365 days in a year for those years.",
  "Children grow faster in the springtime than any other season during the year",
  'Another name for licorice is "Sweet Wood" or "Spanish Juice."',
  "The reason the Animal Crackers box is designed with a string handle is because when the popular circus theme was introduced in 1902 they thought it would also be a good idea to package them with a string as a Christmas novelty so they could be hung from Christmas trees",
  "Sheep can detect other sheep faces like humans do. They can remember up to 50 sheep faces",
  "The loudest insect in the world is the male cicadas, which are like crickets. When they rub their abdomens, the sound made can be heard from 1300 feet",
  "Each year 96 billion pounds of food is wasted in the U.S",
  "In the past 60 years, the groundhog has only predicted the weather correctly 28% of the time. The rushing back and forth from burrows is believed to indicate sexual activity, not shadow seeking",
  "Pretzel snacks have been around for over 1300 years. A European monk invented the snack using used leftover bread dough",
  'Sharks are capable of surviving on average six weeks without eating. The record observed in an aquarium is fifteen months by a species of shark known as the "swell shark."',
  "The destruction of the Berlin Wall began when private citizens began to demolish entire sections of the Wall without interference from government officials on November 9, 1989",
  "Most American women have their first baby when they are 24.3 years old",
  "Frogs do not need to drink water as they absorb the water through their skin",
  "A group of larks is called an exaltation",
  'The Kool Aid Man used to be known as "Pitcher Man" when he was first introduced in 1975',
  "Wheel of Fortune star Vanna White holds the record for putting her hands together approximately 140,000 times to clap",
  "Men sweat more than women. This is because women can better regulate the amount of water they lose",
  "Research has indicated that approximately eleven minutes are cut off the life of an average male smoker from each cigarette smoked",
  "The triangular shape that Toblerone chocolates are packaged in, is protected by law",
  'In 1945, the first "floating ice cream parlor" was built for sailors in the western Pacific. This "floating ice cream parlour" could produce ten gallons of ice cream every seven seconds',
  "The formula for Coca-cola has never been patented",
  "The average day is actually 23 hours, 56 minutes and 4.09 seconds. We have a leap year every four years to make up for this shortfall",
  "Before its name was changed, the African Penguin used be called the Jackass Penguin because of its donkey-like braying call",
  "During the high feeding season, it has been estimated that an adult blue whale can eat up to 40 million krill in one day. (Krill are shrimp like creatures)",
  "Pound for pound, leopards are said to be seven times stronger than humans",
  "One average, men spend 60 hours a year shaving",
  "Botanically a rhubarb is a vegetable. It was changed to a fruit in 1947 by a U.S. Custom Court",
  "Sawney Beane, his wife, 8 sons, 6 daughters, and 32 grandchildren were a family of cannibals that lived in the caves near Galloway, Scotland in the early 17th Century. Although the total number is not known, it is believed they claimed over 50 victims per year. The entire family was taken by an army detachment to Edinburgh and executed, apparently without trial",
  "The movie that grossed the most money that was adapted from a T.V. cartoon is Scooby-Doo",
  "There are five million scent receptors located in a human beings nose",
  "When Coca-Cola was invented, American tourists that visited Spain were surprised to see that Coke was three times as expensive as a glass of brandy",
  "Some silkworms can spin cocoons that contain more than two miles of silk",
  "The mother of famous astronomer Johannes Kepler was accused of being a witch",
  "At one time, Pumpkins were recommended for the removal of freckles and curing snake bites",
  "The Eisenhower interstate system requires that one mile in every five must be straight. These straight sections are usable as airstrips in times of war or other emergencies",
  "Approximately 200 pets are buried in a pet cemetery out of the thousands of pets that die each day",
  "The average Hostess Twinkie is 68 percent air as measured by volume according to university researchers",
  "In Haiti, only 1 out of every 200 people own a car. This is ironic considering approximately 33% of the country's budget on import is spent on equipment for fuel and transportation.",
  "Every U.S. bill regardless of denomination costs just 4 cents to make",
  "About 30% of American admit to talking to their dogs or leaving messages on their answering machines for their dogs while they are away",
  "A duck's quack doesn't echo, and no one knows why",
  'Caterpillar means "hairy cat" in Old French',
  "The ostrich has two toes on each feet which gives it greater speed",
  "On September 3, 1970, a hailstone was found in Coffeyville, Kansas that was eight inches in diameter and weighed 1.67 pounds.",
  "Honorificabilitudinitatibus is the longest English word that consists strictly of alternating consonants and vowels",
  "It can take up to a month for a rattlesnake to re-supply its venom",
  "Close to 3 billion movie tickets are sold in India every year",
  "The word racecar and kayak are the same whether they are read left to right or right to left",
  "There were approximately 2,228 people on board the Titanic when it sank. Of this, only 706 people survived",
  "An elephant can live up to the age of seventy, or in some cases even more",
  "The giant squid has the largest eyes in the world",
  "Elephants can't jump. Every other mammal can.",
  'The name "cranberry" comes from German and Dutch settlers. The berry was intially called "crane berry." The reason it was called this was because when the flowers bloom, the petals of the flowers twist backwards and look very much like the head of a crane. Eventually the name was shortened down to be "cranberry."',
  "In New Mexico, over eleven thousand people have visited a tortilla chip that appeared to have the face of Jesus Christ burned into it",
  "On average, he ratio of yellow kernels to white kernals in a bag of popcorn is 9:1",
  "The first toilet stall in a public washroom is the least likely to be used. It is also the cleanest",
  "In 1955, only 330 Volkswagen Beetle's were sold at a price of $1800 each in the United States.",
  "Printed on the tablet being held by the Statue of Liberty is July IV, MDCCLXXVI",
  "The country of Fiji is made up of 332 islands",
  "Orville Wright, a pilot, was involved in the first aircraft accident. His passenger, a Frenchman, was killed.",
  "The first company to mass produce teddy bears was the Ideal Toy Company",
  "Princess Anne from the British royal family competed in the 1976 Summer Olympics",
  "Brazil produces the most oranges in the world",
  "Average life span of a major league baseball: 7 pitches.",
  "The eyeball of a human weighs approximately 28 grams",
  "A human head remains conscious for about 15 to 20 seconds after it is been decapitated",
  'Witchcraft means "Craft of the Wise Ones."',
  "500,000 kids in the US live in same sex households",
  "In July 1874, a swarm of Rocky Mountain locusts flew over Nebraska covering an area estimated at 198,600 square miles. It is estimated that the swarm contained about 12.5 trillion insects. These insects became extinct thirty years later",
  "Tropical rainforests cover about 7% of the Earth and receive over 80 inches of rain every year",
  "The feet have approximately 250,000 sweat glands",
  "Approximately 7.5% of all office documents get lost",
  "The desert tortoise can live without having to drink any water. It extracts the water it needs from the vegetation it eats",
  "There were 13 couples celebrating their honeymoon on the Titanic",
  "A cat has 32 muscles in each ear",
  "There are 336 dimples on a regulation golf ball",
  "Elvis Presley had a twin brother named Jesse Garon Presley who died at birth",
  "Robert Wadlow is the tallest man recorded in history. He grew to be eight feet and eleven inches and weighed 490 pounds when he died",
  "Bank robber John Dillinger played professional baseball",
  "Research indicates that plants grow healthier when they are stroked.",
  "France is known as the perfume capital of the world",
  "According to psychologists, the shoe and the foot are the most common sources of sexual fetishism in Western society",
  "Constipation is caused when too much water is absorbed in the large intestine and the feces become dry",
  "One ton of grapes can produce 720 bottles of wine",
  "Eating about twenty tart cherries a day could reduce inflammatory pain and headache pain",
  "In 2001, the five most valuable brand names in order were Coca-Cola, Microsoft, IBM, GE, and Nokia",
  "Milk chocolate was invented in Switzerland by David Peter in 1876",
  "In November 1999, two women were killed by a lightning bolt. The underwire located in their bras acted as a electrical conductors, and when the lightning bolt hit the bra they left burn marks on their chest",
  "Basketball was invented by Canadian James Naismith in 1891",
  "Over 100,000 birds and sea animals are killed every year due to plastic garbage",
  "The big toe is the foot reflexology pressure point for the head",
  "85% of weddings are held in a synagogue or church",
  "The sport of surfing originated in Hawaii",
  "It is possible to lead a cow upstairs...but not downstairs",
  "Before soccer referees started using whistles in 1878, they used to rely on waving a handkerchief",
  "Tobacco kills more Americans each year than alcohol, cocaine, crack, heroin, homicide, suicide, car accidents, fire and AIDS combined",
  "The best time for a person to buy shoes is in the afternoon. This is because the foot tends to swell a bit around this time",
  "Dead cells in the body ultimately go to the kidneys for excretion",
  "Americans, on average, spend 18% of his or her income on transportation as compared to only 13% spent on food",
  "There are some species of snails that are venomous. Their venom can be fatal to humans",
  "The first box of Crayola that was ever sold had the same eight colours that are sold in the box today consisting of red, blue, yellow, green, violet, orange, black and brown. The box was sold for a nickel in 1903",
  "A turtle can breathe through its butt.",
  "The rarest coffee in the world is Kopi Luwak, which is found in Indonesia. It cost about $300 a pound",
  "The average America online user spends 70 minutes day online",
  "People of Ancient China believed that swinging your arms could cure a headache",
  "In 1938, Cliquot Club ginger ale was the first soft drink to be canned",
  "The largest apple pie ever baked was forty by twenty three feet",
  "Roughly 44% of junk mail is thrown away unopened",
  "Catfish have tastebuds located on their whiskers",
  "The laundry detergent Tide, has a market share of about forty percent market",
  "A Canadian, Troy Hurtubise, spent $100,000 and almost went bankrupt building a RoboCop style suit so that he could withstand a bear attack",
  "The 3 most valuable brand names on earth: Marlboro, Coca-Cola, andBudweiser, in that order.",
  "The only two days of the year in which there are no professional sports games (MLB, NBA, NHL, or NFL) are the day before and the day after the Major League all-stars Game",
  "In the United States, 8.5 million cosmetic surgical and non-surgical procedures were done in the year 2001",
  "The Bible has been translated into Klingon.",
  'Ian Fleming named his character "James Bond" after real-life ornithologist and author',
  "Most dinosaurs walked on their toes.",
  "On December 17 1991, the Cleveland Cavaliers beat the Miami Heat 148-80, the largest margin of victory in an NBA game",
  "There are mirrors on the moon. Astronauts left them so that laser beams could be bounced off of them from Earth. These beams help give us the distance to the moon give or take a few metres.",
  "The U.S. army packs Tabasco pepper sauce in every ration kit that they give to soldiers",
  "The trunk of an elephant can hold up to two gallons of water",
  "Every year, an igloo hotel is built in Sweden that has the capacity to sleep 100 people",
  "During the holiday season, approximately $220 million worth of Poinsettias are sold",
  "A newborn kangaroo weighs approximately 0.03 ounces and is small enough to fit in a teaspoon",
  "When Scott Paper Co. first started manufacturing toilet paper they did not put their name on the product because of embarrassment",
  "The most senior crayon maker Emerson Moser retired after making 1.4 billion crayons for Crayola. It was then that he revealed that he was actually colorblind",
  "The highest point in Pennsylvania is lower than the lowest point inColorado.",
  "Scientists have determined that having guilty feelings may actually damage your immune system",
  '"Go." is the shortest complete sentence in the English language',
  "There are more than 250,000 rivers in the United States, which amounts to 3.5 million miles of rivers",
  "Forty-one percent of women apply body and hand moisturizer at least three times a day",
  'Pretzel that have no salt on them are called "baldies."',
  "The 1912 Olympics was the last Olympics that gave out gold medals that were made entirely out of gold",
  "Monopoly is the best-selling board game in the world",
  'There was a book written fourteen years before the sinking of the Titanic happened titled "Futility" by Morgan Robertson. This book was remarkably similar to the tragedy that happened to the Titanic in 1912',
  "One ounce of chocolate has about 20 mg of caffeine in it",
  "A giraffe can go longer without water than a camel",
  "Vikings, after killing their enemies, used their skulls as drinking vessels",
  "Studies have shown that classical music helps cows produce more milk",
  "Two out of five people end up marrying their first love",
  "The Hawaiian alphabet only has 12 letters",
  'The name "Muppet" was coined by Jim Henson. The word was made from a combination of the word "marionette" and "puppet."',
  "In the Sahara Desert, there is a town named Tidikelt, which did not receive a drop of rain for ten years",
  "The Christmas season begins after sunset on December 24th and lasts until January 5th. This is also known as the Twelve Days of Christmas",
  "Mosquitoes are attracted to the color blue more than any other color",
  "In Italy, Santa Claus is known by the name Babbo Natale",
  "Two objects have struck the earth with enough force to destroy a whole city. Each object, one in 1908 and again in 1947, struck regions of Siberia. Not one human being was hurt either time",
  "When blue whales are first born, they gain as much as 200 pounds a day while they are calves",
  "Families who do turn off the television during meals tend to eat healthier. This was regardless of family income, or education",
  "About 25 percent of all the energy consumed in the US is from natural gas",
  "American novelist Mark Twain was the first known author to submit a typed manuscript",
  "If you fart consistently for 6 years and 9 months, enough gas is produced to create the energy of an atomic bomb",
  "Canada is the only country not to win a gold medal in the Summer Olympic games while hosting the event",
  "The sound made by the toadfish when mating underwater is so loud that it can be heard by humans on the shore",
  "In America, approximately 20% of children between the ages of 2 - 7 have televisions in their rooms",
  "Traveling by air is the safest means of transportation.",
  'In 1996, toy company Mattel released a "Harley Davidson" Barbie. This dolls distinctive feature is a birth mark on her face that changes position with every new release of the doll',
  "The most common injury caused by cosmetics is to the eye by a mascara wand",
  "There have been close to 200 coups and counter-coups in the country of Bolivia",
  "On average, pigs live for about 15 years",
  "Roughly 42% of people in the United Kingdom snore",
  "No NFL team which plays its home games in a domed stadium has ever won a Superbowl",
  'Beluga whales which are also called "white whales" are not born white. They are born grey in color, and by the age of six become completely white',
  'Tiger Woods is the first athlete to has been named "Sportsman of the Year" by magazine Sports Illustrated two times',
  "The eight most popular foods to cause food allergies are: milk, eggs, wheat, peanuts, soy, tree nuts, fish, and shellfish",
  "Club Direct, a travel insurance company in Britain, provides insurance plans for protection from falling coconuts",
  "There are some bananas that are red instead of yellow",
  "Only one out of every three people wash their hands when leaving a public bathroom",
  "570 gallons of paint would be needed to paint the outside of the White House",
  "Baby robins eat 14 feet of earthworms every day",
  "Every three seconds a baby is born somewhere in the world",
  "The total mileage driven by all U-Haul trucks in a year is enough to move a person from the Earth to the moon five times a day for an entire year",
  "The Eisenhower interstate system requires that one mile in every fivemust be straight. These straight sections are usable as airstrips intimes ofwar or other emergencies.",
  "Pluto was discovered on February 10, 1930 by Clyde Tombaugh",
  "Termites have been around for over 250 million years",
  "The average person changes their career every 13 years",
  "The New York Yankees have appeared in the World Series a league leading 38 times and won 26 titles",
  "Approximately 18 billion disposable diapers end up in landfills each year. These diapers can takes as long as 500 years to finally decompose",
  "Over 4.5 billion sticks have Trident gum have been chewed. If the stick of gum were laid out end to end they could circle the globe approximately 1.8 times",
  "Oak trees can live 200 or more years",
  "The brain of an ant has about 250,000 brain cells",
  "About 26 per cent of all indoor water used by households in Sydney, Australia are for laundry",
  "A rainbow can occur only when the sun is 40 degrees or less above the horizon",
  "If you spray an antiseptic spray on a polar bear, its fur will turn purple",
  "Over $7 billion a year is spent on chocolates by consumers",
  "During World War II, Russians used dogs strapped with explosives to blow up German tanks. They trained the dogs to associate the tanks with food and ended up destroying about 25 German tanks using this method",
  "Butterflies taste with their feet",
  "St. Louis, Missouri was the first U.S. city to host the summer Olympics in 1904",
  'The phrase "rule of thumb" is derived from an old English law whichstated that you couldn\'t beat your wife with anything wider than yourthumb.',
  "Every year Alaska has about 5,000 earthquakes, 1,000 of which measure above 3.5 on the Richter scale",
  "A fetus develops fingerprints at eighteen weeks",
  "It takes about a half a gallon of water to cook macaroni, and about a gallon to clean the pot",
  "The cornea is the only living tissue in the human body that does not contain any blood vessels",
  "In the U.S. peanuts account for 66% of all snack nuts",
  "There are approximately 7,000 feathers on an eagle",
  "Sharks can sense a drop of blood from a mile away",
  "As a defense mechanism, the North American Opossum closes its eyes and becomes totally limp. Basically it plays dead",
  "The longest town name in the world has 167 letters",
  "A sneeze zooms out of your mouth at over 600 m.p.h",
  "A cesium atom in an atomic clock that beats over nine billion times a second.",
  "The mythical Scottish town of Brigadoon appears for one day every one hundred years",
  "Kermit the frog delivered the commencement address at Southampton College located in the state of New York in 1996",
  "In World War II, the German submarine U-120 was sunk by a malfunctioning toilet",
  'The phrase "Often a bridesmaid, but never a bride," actually originates from an advertisement for Listerine mouthwash from 1924',
  "Over 50% of lottery players go back to work after winning the jackpot",
  "The largest cultivated crop in the United States is corn",
  "Walt Disney holds the record for the most Oscar nominations with sixty-four",
  "On average, Americans eat one hundred acres of pizza a day. This amounts to about three hundred fifty slices per second",
  "As an iceberg melts, it makes a fizzing sound because of the compressed air bubbles popping in the ice",
  "The Arctic Ocean covers an area of about 14,056,000 sq miles",
  "The first known contraceptive was crocodile dung, used by Egyptians in 2000 B.C",
  "Most toilets flush in E flat",
  "Bile produced by the liver is responsible for making your feces a brownish, green colour",
  'At one time the group "Grateful Dead" were called "The Warlocks."',
  "Bats can detect food up to 18 feet away and what type of insect the food may be using their sense of echolocation",
  "At the equator the Earth spins at about 1,038 miles per hour",
  "People whose mouth has a narrow roof are more likely to snore. This is because they have less oxygen going through their nose",
  "In one day, a human sheds 10 billion skin flakes. This amounts to approximately two kilograms in a year",
  "On average, an American home has 3-10 gallons of hazardous materials",
  "On average, 35 meters of hair fibre is produced on the adult scalp",
  "Dalmatian puppies do not have any spots on them when they are born. They actually develop them as they get older",
  "Male goats will pee on each other in order to attract mates",
  "A dog by the name of Laika was launched into space aboard the Russian spacecraft Sputnik 2 in 1957",
  "In 2002, dogs have killed more people in the U.S. than the Great White shark has killed in the past 100 years",
  "The study of twins is known as gemellology",
  'On an American one-dollar bill, there is an owl in the upper right-hand corner of the "1" encased in the "shield" and a spider hidden in the front upper right-hand corner',
  "During one seven year period, Thomas Edison obtained approximately three hundred patents. In is whole life he obtained over one thousand patents.",
  "When Black Jack Ketchum was hung back in 1901 in Clayton New Mexico, the noose actually ended up taking his head off. The head had to be sewn back on so Black Jack could be buried properly",
  "Every 40,000 children are killed by fires",
  "The highest recorded speed of a sneeze is 165 km per hour",
  "In 1985, a pregnant women was falsely accused of shoplifting a basketball",
  "In every episode of Seinfeld there is a Superman somewhere",
  "The adult electric eel can produce a five hundred volt shock, which is enough to stun a horse",
  "When the are in danger, kangaroos will beat the ground loudly with their hind feet",
  "To manufacture a new car approximately 148,000 liters of water is needed.",
  "In 410 A.D. Alaric the Visigoth demanded that Rome give him three thousand pounds of pepper as ransom",
  "Actress Jamie Lee Curtis invented a special diaper for babies that has a pocket",
  "Honeybees use the sun as a compass which helps them navigate",
  "An average driver spends approximately 2 hours and 14 minutes kissing in their car in a lifetime",
  'In gangster slang, a boxing match that is fixed is called a "barney."',
  "In 75% of American households, women manage the money and pay the bills",
  "In a year, an average person uses the toilet 2500 times a year",
  "A honey bee has four wings",
  "The Bank of America was originally called the Bank of Italy until the founder, Amedeo Giannini, changed the name in 1930",
  "Other than London, Liverpool is the most filmed British city, and was used to film more than 140 films in 2002",
  "The body of the average baby is 75% water",
  "A Chinese Scientist discovered that the Earth is round during the Han Dynasty by measuring the sun and moon's path in the sky. He recorded this fact down in the imperial records but went unnoticed until it was unearthed recently but Chinese archaeologists.",
  "Each year, Americans throw away 25 trillion Styrofoam cups",
  "There are more than 2,000 different varieties of cheese in the world",
  "On average, Guinness sells 7 million glasses of beer a day",
  "An artist from Chicago named Dwight Kalb created a statue of Madonna made out of 180 pounds of ham",
  "Reports from owners of cats and dogs indicate that 21% of dogs and 7% of cats snore",
  "The scarlet tanager, a songbird native to Illinois, can eat as many as 2,100 gypsy-moth caterpillars in one hour",
  "To make one raindrop of water, it takes approximately a million cloud droplets",
  "At 120 miles per hour, a Formula One car generates so much downforce that it can drive upside down on the roof of a tunnel",
  "The smallest bone in the human body is the stapes bone which is located in the ear",
  "India used to be the richest country in the world until the British invasion in the early 17th Century",
  "The first owner of the Marlboro Company died of lung cancer",
  'Some African tribes refer to themselves as "motherhoods" instead of families',
  "Between 1902 and 1907, the same tiger killed 434 people in India",
  'The word vaccine comes from the Latin word "vacca," which means cow. This name was chosen beacause the first vaccination was derived from cowpox which was given to a boy',
  "James Bond is also known as Mr. Kiss-Kiss-Bang-Bang",
  "A snail can crawl across a razor blade without getting injured. This is possible because they excrete a slime that protects them",
  "Behram, an Indian thug, holds the record for most murders by a single individual. He strangled 931 people between 1790-1840 with a piece of yellow and white cloth, called a ruhmal. The most murders by a woman are 612, by Countess Erzsebet Bathory of Hungary",
  "Approximately 97.35618329% of all statistics are made up",
  "The largest spider ever was the Megarachne which had a diameter of 50 cm. The fossil was found in Argentina",
  "In Russia, when flowers are given for a romantic occasions, flowers are given in odds numbers as even number of flowers is given at funerals only",
  "Next to man, the porpoise is the most intelligent creature on earth",
  "The hippopotamus has the capability to remain underwater for as long as twenty-five minutes",
  "The Australian box-jellyfish has eight eyes",
  "In 1916, an elephant was tried and hung for murder in Erwin, Tennessee",
  "A sheep, a duck and a rooster were the first passengers in a hot air balloon",
  "The liquid inside young coconuts can be used as substitute for blood plasma",
  "In the UK, one third of accidental deaths that happen occur in the home",
  "After the U.S Civil War, about 33%-50% of all U.S. paper currency in circulation was counterfeit",
  "Michael Jordan makes more money from Nike annually than all of the Nike factory workers in Malaysia combined",
  "The Hawaiian alphabet only has 12 letters",
  "Tycho Brahe, a 16th century astronomer, lost his nose in a duel with one of his students over a mathematical computation. He wore a silver replacement nose for the rest of his life",
  "Termites do more damage in the U.S. ever year than all the fires, storms and earthquakes combined. They do an average of $750 million in damage annually",
  "Burger King restaurants serve over 400 million ounces of orange juice annually",
  "Each year the Pentagon estimates their computer network is hacked about 250,000 times annually",
  "The first president to ride in an airplane was",
];
